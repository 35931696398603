  :host {
    --header-spacing: var(--sl-spacing-medium);
    --body-spacing: var(--sl-spacing-medium);
    --footer-spacing: var(--sl-spacing-medium);
  }

  .dialog__panel {
    overflow: hidden;
  }

  .dialog__header {
    background-color: var(--sl-color-neutral-50);
    border-bottom: 1px solid var(--sl-color-neutral-100);
  }

  .dialog__title {
    padding-top: calc(var(--sl-spacing-small) + 0.2rem);
    padding-bottom: var(--sl-spacing-small);
    font-size: var(--font-size-base);
    font-weight: var(--sl-font-weight-medium);
    line-height: 1;
  }

  .dialog__close {
    --header-spacing: var(--sl-spacing-x-small);
  }

  .dialog__body {
    line-height: var(--sl-line-height-normal);
  }

  .dialog__footer {
    padding-top: var(--sl-spacing-small);
    padding-bottom: var(--sl-spacing-small);
    border-top: 1px solid var(--sl-color-neutral-100);
  }
